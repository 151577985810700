<template>
  <div
    class="standard-horizontal-card"
    :style="{ width: width + '%' }"
    @click="selected()"
  >
    <div class="picture">
      <picture>
        <source :srcset="image.webp" type="image/webp" />
        <img :src="image.png" alt="alt-image" class="image" />
      </picture>
    </div>

    <div v-if="title && description" class="card-text">
      <h4 v-if="title" class="sl__h4 card-title">
        {{ title }}
      </h4>

      <div
        v-if="description"
        v-html="description"
        class="card-description"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StandardHorizontalCard',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    description: {
      type: String,
      default: '',
      required: false,
    },
    width: {
      type: Number,
      default: 100,
      validator: value => value === 50 || value === 100,
    },
    image: {
      type: Object,
      required: false,
      validator: value => value.hasOwnProperty('png') && value.hasOwnProperty('webp'),
    },
  },
  methods: {
    selected() {
      this.$emit('selected')
    },
  },
}
</script>

<style lang="scss" scoped>
.standard-horizontal-card {
  display: inline-flex;
  align-items: flex-start;
  justify-content: left;
  padding: 1.5em;
  gap: 2em;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 0.375em;
  border: 1px solid #CDCDCD;
  background: #FFF;

  &:hover {
    background-color: #F2FBFA;
    cursor: pointer;
  }

  .picture {
    display: flex;
    padding-right: 1.5em;
    align-items: center;
  }

  source, img {
    width: 7em;
    height: auto;
    align-self: center;
  }
}

.card-title {
  font-weight: bold;
  margin-top: 0;
}

.card-description {
  word-break: break-word;
}
</style>
