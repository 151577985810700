var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "standard-horizontal-card",
      style: { width: _vm.width + "%" },
      on: {
        click: function ($event) {
          return _vm.selected()
        },
      },
    },
    [
      _c("div", { staticClass: "picture" }, [
        _c("picture", [
          _c("source", {
            attrs: { srcset: _vm.image.webp, type: "image/webp" },
          }),
          _c("img", {
            staticClass: "image",
            attrs: { src: _vm.image.png, alt: "alt-image" },
          }),
        ]),
      ]),
      _vm.title && _vm.description
        ? _c("div", { staticClass: "card-text" }, [
            _vm.title
              ? _c("h4", { staticClass: "sl__h4 card-title" }, [
                  _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
                ])
              : _vm._e(),
            _vm.description
              ? _c("div", {
                  staticClass: "card-description",
                  domProps: { innerHTML: _vm._s(_vm.description) },
                })
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }